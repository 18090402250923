.installprompt {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000068;
}

.installpromptmodal {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid;
    align-items: center;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
}