.Media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Media__container {
    max-width: 800px;
}
