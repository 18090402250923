.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.appLogoMin {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo, .appLogoMin {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.toast {
  position: fixed;
  z-index: 10;
  bottom: 80px;
  right: 0;
}

.show {
  transition: fade 600ms linear;
  padding: 32px;
  background-color: #333;
  color: #f9f9f9;
  font-size: 16px;
}

.media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container, section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
  padding: 16px;
}

/* .poster {
  background-image: url('/public/assets/poster_white.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100);
    opacity: 1;
    display: none;
  }
}
