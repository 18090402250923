.timetable {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}

.hourPicker {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    align-items: center;
    align-content: center;
    max-width: 320px;
}

.hour {
    position: relative;
    display: flex;
    justify-content: center;
    border: 1px solid #3d3d3d8c;
    padding: 15px;
    color: #000;
    outline: none;
    border-radius: 5px;
    /* width: 65px;
    height: 47px; */
}

.hour.CONFIRMED {
    border-color: #e00404d4;
    background-color: #e00404d4;
}

.hour.SELECTED {
    border-color: #08d008d4;
    background-color: #08d008d4;
}

.hour.BOOKED {
    border-color: #e0dc04d4;
    background-color: #e0dc04d4;
}

.hour.CANCELLING {
    border-color: red;
    background-color: #e0dc04d4;
}

.react-calendar .highlight {
    outline: 2px solid red;
}

.bookBtn {
    margin-top: 15px!important;
}

.hourChecker {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: -100%;
    z-index: 9;
}

.hour.BOOKED:hover .tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;;
}