.feed {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    padding-bottom: 100px;
}

.feed_block {
    margin-top: 20px;
}

.post {
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 15px;
}

.date {
    text-align: right;
    font-size: 10px;
}