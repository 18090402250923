.Audio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Audio__container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
